/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-07 15:48:15 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-24 19:10:35
 */
<template>
  <div class="wrongQuestionRecord">
    <!-- <div class="ctbl">
      <el-row type="flex" align="middle">
        <el-col :span="20">
          <div class="wrongTopic">
            <div>
              <p>
                {{lists.AllWrongCount}}
                <span>题</span>
              </p>
              <p>错题总数</p>
            </div>
          </div>
          <div class="wrongTopic">
            <div>
              <p>
                {{lists.NowWrongCount}}
                <span>题</span>
              </p>
              <p>今日错题</p>
            </div>
          </div>
          <div class="wrongTopic">
            <div>
              <p>
                {{lists.Accuracy}}
                <span>%</span>
              </p>
              <p>正确率</p>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button>错题重做</el-button>
        </el-col>
      </el-row>
    </div>
    <h3 class="wrongQuestionRecord_title">错题情况</h3>
    <div class="wrongQuestionRecord_cont">
      <el-row type="flex" align="middle">
        <el-col :span="15"> 题型 </el-col>
        <el-col :span="5"> 错题数 </el-col>
        <el-col :span="4"> </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="15"> 单选题 </el-col>
        <el-col :span="5"> {{lists.SingleWrongCount}} </el-col>
        <el-col :span="4">
          <el-button>重新做题</el-button>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="15"> 多选题 </el-col>
        <el-col :span="5"> {{lists.MultipleWrongCount}} </el-col>
        <el-col :span="4">
          <el-button>重新做题</el-button>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="15"> 判断题 </el-col>
        <el-col :span="5"> {{lists.JudgeWrongCount}} </el-col>
        <el-col :span="4">
          <el-button>重新做题</el-button>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="15"> 填空题 </el-col>
        <el-col :span="5"> {{lists.CompletionWrongCount}} </el-col>
        <el-col :span="4">
          <el-button>重新做题</el-button>
        </el-col>
      </el-row> -->
    <div v-for="(lb, b) in list" :key="b" class="paperBank">
      <div class="title">{{ lb.ChapterName }}</div>
      <div class="questionTitle">
        <p class="tmtype">
          <span
            :class="
              lb.QuestionCategory == 0
                ? 'bgc_3A78F9'
                : lb.QuestionCategory == 1
                ? 'bgc_FBB03B'
                : lb.QuestionCategory == 2
                ? 'bgc_00AE62'
                : lb.QuestionCategory == 3
                ? 'bgc_B938B5'
                : lb.QuestionCategory == 4
                ? 'bgc_9682EC'
                : 'bgc_37E6CF'
            "
          >
            {{
              lb.QuestionCategory == 0
                ? "单选"
                : lb.QuestionCategory == 1
                ? "多选"
                : lb.QuestionCategory == 2
                ? "判断"
                : lb.QuestionCategory == 3
                ? "填空"
                : lb.QuestionCategory == 4
                ? "简答"
                : "论述"
            }}
          </span>
          <span
            v-if="
              lb.QuestionCategory == 0 ||
              lb.QuestionCategory == 1 ||
              lb.QuestionCategory == 2
            "
            :class="lb.StudentAnswerStr != lb.AnswerStr ? 'details_sign' : ''"
            >题目{{ b + 1 }}：{{ lb.QuestionTitle }}</span
          >
          <span v-else>题目{{ b + 1 }}：{{ lb.QuestionTitle }}</span>
        </p>
      </div>
      <el-radio-group
        v-if="lb.QuestionCategory === 0"
        v-model="lb.StudentAnswerStr"
        disabled
      >
        <p v-for="(lbb, p) in lb.BankItem" :key="p">
          <el-radio :label="lbb.ItemNum"
            >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
          >
        </p>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </el-radio-group>
      <el-checkbox-group
        v-else-if="lb.QuestionCategory === 1"
        v-model="lb.StudentAnswerStr"
        disabled
      >
        <p v-for="(lbb, p) in lb.BankItem" :key="p">
          <el-checkbox :label="lbb.ItemNum"
            >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
          >
        </p>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div v-if="lb.parsingShow == true" class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </el-checkbox-group>
      <el-radio-group
        v-else-if="lb.QuestionCategory === 2"
        v-model="lb.StudentAnswerStr"
        disabled
      >
        <p v-for="(lbb, p) in lb.BankItem" :key="p">
          <el-radio :label="lbb.ItemNum"
            >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
          >
        </p>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div v-if="lb.parsingShow == true" class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </el-radio-group>
      <div v-else-if="lb.QuestionCategory === 3">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入填空,多个填空以中文；隔开"
          v-model="lb.StudentAnswerStr"
          disabled
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div v-if="lb.parsingShow == true" class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </div>
      <div v-else-if="lb.QuestionCategory === 4">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入简答"
          v-model="lb.StudentAnswerStr"
          disabled
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div v-if="lb.parsingShow == true" class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </div>
      <div v-else-if="lb.QuestionCategory === 5">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入论述"
          v-model="lb.StudentAnswerStr"
          disabled
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          round
          v-if="lb.parsingShow == false"
          @click="parsClick(lb)"
          >查看答案及解析</el-button
        >
        <div v-if="lb.parsingShow == true" class="js">
          <div>【答案】: {{ lb.AnswerStr == null ? "无" : lb.AnswerStr }}</div>
          <div>
            【解析】:
            <span
              v-html="lb.QuestionAnalysis == null ? '无' : lb.QuestionAnalysis"
            ></span>
          </div>
          <div>【点拨】: {{ lb.Dial == null ? "无" : lb.Dial }}</div>
        </div>
      </div>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { questionBankQueryStudentWrongRecordList } from "@/api/personal";
import noData from "@/components/noData";
export default {
  data () {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  components: {
    noData
  },
  created () { },
  mounted () {
    this.route = this.$route.query
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    //查看答案及解析
    parsClick (obj) {
      this.$set(obj, "parsingShow", true);
    },
    async init () {
      let parm = {
        courseId: this.route.CourseId,
        studentId: this.userInfo.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await questionBankQueryStudentWrongRecordList(parm);
      if (res.success === true) {
        res.response.data.forEach((it) => {
          if (it.QuestionCategory == 1) {
            it.AnswerStr = it.AnswerStr.split("");
            it.StudentAnswerStr = it.StudentAnswerStr.split(",");
          }
          this.$set(it, "parsingShow", false);
        });
        this.list = res.response.data;
        this.total = res.response.dataCount
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrongQuestionRecord {
  .ctbl {
    // padding: 30px;
    margin: -10px -30px;
    padding: 10px 0;
    background-color: #f5f5f5;
    .el-row {
      background-color: #fff;
      padding: 30px;
      .wrongTopic {
        width: 160px;
        height: 113px;
        background-color: #f4f3f3;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        float: left;
        margin-left: 10px;
        & > div {
          p {
            color: #777777;
            line-height: 100%;
            &:first-child {
              margin-bottom: 10px;
              color: #333333;
              font-weight: bold;
              font-size: 24px;
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
      .wrongTopic + .wrongTopic {
        margin-left: 50px;
      }
    }
  }
  .wrongQuestionRecord_title {
    font-size: 18px;
    line-height: 100%;
    padding: 30px 0;
  }
  .wrongQuestionRecord_cont {
    /deep/ .el-row {
      border-bottom: 1px solid #f0eeee;
      padding: 20px 0;
      .el-col {
        &:nth-child(1) {
          text-indent: 78px;
        }
        &:nth-child(2) {
          text-align: center;
        }
      }
      &:first-child {
        height: 50px;
        background-color: #f3f3f3;
        padding: 0;
      }
    }
  }
  .el-button {
    width: 88px;
    line-height: 34px;
    padding: 0;
    height: 34px;
    color: #ffffff;
    background-color: #1288f4;
    border-radius: 4px;
  }
}
.title {
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  color: #999999;
}
.js {
  font-size: 14px;
  line-height: 175%;
}
.paperBank {
  margin-bottom: 5px;
  padding: 20px 30px;
  background-color: #fff;
  .questionTitle {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .correction {
      margin-left: 5px;
      color: #c5c5c5;
      font-size: 12px;
      width: 40px;
      cursor: pointer;
    }
    p {
      line-height: 22px;
      // height: 22px;
    }
    .tmtype {
      flex: 1;
      span {
        &:first-child {
          display: inline-block;
          background-color: #3a78f9;
          line-height: 22px;
          height: 22px;
          color: #fff;
          padding: 0 8px;
          border-radius: 20px;
          margin-right: 10px;
          font-size: 12px;
        }
      }
    }
    .tmmc {
      margin-top: 10px;
    }
  }
  .el-radio-group,
  .el-checkbox-group {
    .el-checkbox {
      // display: flex;
      // align-items: center;
    }
    .el-radio,
    .el-radio__input,
    .el-checkbox,
    .el-checkbox__input {
      white-space: normal !important;
      line-height: 175%;
    }
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .el-textarea {
    margin-bottom: 10px;
  }
}
</style>